<template>
  <div class="floating_btn">
    <a
      target="_blank"
      aria-label="WhatsApp da SOS Motoka"
      href="https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20SOS%20Motoka,%20gostaria%20de%20um%20orçamento."
    >
      <div class="contact_icon">
        <img src="@/assets/whatsapp-32.svg" alt="WhatsApp" width="32" height="32" loading="lazy" />
      </div>
    </a>
  </div>
  <div class="pg-footer">
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-content-column">
          <div class="footer-logo">
            <img class="logo" src="@/assets/logo.webp" width="120" height="40" alt="Logo SOS Motoka" loading="lazy" />
          </div>
        </div>
        <div>
          <div>
            <h2 v-once>Serviço de Guincho Especializado para Motos</h2>
            <p v-once>
              Se você está enfrentando dificuldades com sua motocicleta e necessita de um serviço confiável de guincho,
              você veio ao lugar certo. Na SOS Motoka, somos especializados em oferecer serviços de guincho
              especialmente projetados para motos, assegurando o transporte seguro e cuidadoso da sua motocicleta até o
              destino desejado.
            </p>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright-wrapper">
          <p class="footer-copyright-text">
            <router-link class="footer-copyright-link" to="#" target="_self">
              © 2023, Todos os direitos reservados.
            </router-link>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.pg-footer {
  font-family: 'Roboto', sans-serif;
}

.footer {
  padding: 1rem 0 !important;
  color: #fff;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.footer-content {
  padding: 40px 15px 20px;
}

.footer-logo img {
  display: block;
  margin: 0 auto;
}

.footer-copyright {
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contact_icon {
  background-color: #42db87;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px #999;
  transition: all 300ms ease-in-out;
}

.contact_icon img {
  margin: 0;
}

.text_icon {
  margin-top: 8px;
  font-size: 13px;
}
</style>

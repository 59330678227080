<template>
  <div class="home">
    <Start msg="Bem-vindo ao seu aplicativo SOS Motoka"/>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    Start: require('@/components/Start.vue').default
  }
}
</script>

<template>
  <div class="partners-top-banner" :style="{maxWidth: screenWidth <= 800 ?  '100vw' : '100vw'}">
    <img style="min-width: 100vw;" src="@/assets/b_2000.webp" srcset="@/assets/a.webp 600w, @/assets/b_1080.webp 1080w, @/assets/b_2000.webp 2000w" alt="description" />
    <!-- <h1 v-once class="white-text-align-left e">GUINCHO DE MOTO 24 HORAS</h1> -->
  </div>
</template>

<style lang="scss" scoped>
.partners-top-banner {
  h1{
    position: absolute;
  }
  max-width: 80vw !important;
  margin: auto !important;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.e {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #fff000, 0 0 20px #3a76f1;
  letter-spacing: 0.05em;
  line-height: 100%;
  font-size: 5em;
  word-break: normal;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #333333a5;

  @media only screen and (max-width: 600px) {
    font-size: 4rem;
  }
}

.white-text-align-left {
  color: transparent;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 90px;
}

h1, h5 {
  font-size: 38px;
  line-height: 44px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h5 {
  color: #fff !important;
}
</style>

<script>
export default {
  name: 'b-cps',
  data () {
    return {
      screenWidth: window.innerWidth
    }
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screenWidth = window.innerWidth
    }
  }
}
</script>

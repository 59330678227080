<template>
        <a href="tel:19993537939"
     target="_blank"  rel="noopener noreferrer"><span class="btn-banner">LIGUE AGORA 24 HORAS</span></a>
</template>
<style lang="scss">
.btn-banner {
  margin: 1rem 5rem !important;
  background: #0549be;
  // box-shadow: 0 0 5px #0549becf, 0 0 10px #0549becf, 0 0 10px #0549be, 0 0 20px #0549be, 0 0 30px #0549be, 0 0 40px #0549be, 0 0 50px #0549be;

  // text-shadow: 0 0 1px #0549becf, 0 0 10px #0549becf, 0 0 1px #33333369, 0 0 2px #33333369, 0 0 3px #33333369, 0 0 4px #33333369, 0 0 5px #33333369;
  border: 0!important;
  color: #fff;
  padding: 30px 30px!important;
  border-radius: 99px;
  width: 25%;
  min-height: 56px!important;

  @media only screen and (max-width: 600px) {
    max-width: 90vw !important;
    min-width: 90vw !important;
    margin: 0 !important;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/pecas',
    name: 'Pecas',
    component: () => import('../views/Pecas.vue')
  },
  {
    path: '/oficinas-credenciadas',
    name: 'OficinasCredenciadas',
    component: () => import('../views/OficinasCredenciadas.vue')
  },
  {
    path: '/para-usuarios',
    name: 'ParaUsuarios',
    component: () => import('../components/Usuarios.vue')
  },
  {
    path: '/para-empresas',
    name: 'ParaEmpresas',
    component: () => import('../components/Empresas.vue')
  },
  {
    path: '/para-profissionais',
    name: 'ParaProfissionais',
    component: () => import('../components/Profissionais.vue')
  },
  {
    path: '/xml',
    name: 'Xml',
    component: () => import('../components/GenerateSitemap.vue')
  },
  {
    path: '/:id',
    name: 'Key',
    component: () => import('../components/Galeria.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
